import { createElement, forwardRef } from "react";
import { parseThemeColor, useMantineTheme } from "@mantine/core";
import { IconProps as TablerIconProps } from "@tabler/icons-react";
import _ from "lodash";

export type MantineTablerProps = {
  icon: React.FC<TablerIconProps>;
} & TablerIconProps;

// This is ripped from the ThemeIcon source. In the future we
// may wish to embed this in the theme but for now it's fine.
const sizes = {
  xs: 16,
  sm: 20,
  md: 26,
  lg: 32,
  xl: 40,
};

/**
 * Provides a wrapper around a Tabler icon
 * so you can directly access the mantine theme.
 */
export const MantineTabler = forwardRef<SVGSVGElement, MantineTablerProps>(
  ({ icon: Icon, color: themeColor = "gray.6", size: themeSize, ...iconProps }, ref) => {
    const theme = useMantineTheme();
    const parsed = parseThemeColor({ color: themeColor, theme });

    const size = themeSize ? _.get(sizes, themeSize, themeSize) : themeSize;
    let color = "gray";

    if (parsed.isThemeColor) {
      if (parsed.shade === undefined) {
        color = `var(--mantine-color-${color}-filled)`;
      } else {
        color = `var(--mantine-color-${parsed.color}-${parsed.shade})`;
      }
    } else {
      color = themeColor;
    }

    return createElement(Icon, {
      // @ts-expect-error TS2769
      ref,
      color,
      size,
      ...iconProps,
    });
  },
);

MantineTabler.displayName = "MantineTabler";

export function mantineTablerFactory(displayName: string, props: Partial<MantineTablerProps>) {
  const IntermediateComponent = forwardRef<SVGSVGElement, Partial<MantineTablerProps>>(
    (instanceProps, ref) => {
      // Ensure that the icon prop is always provided
      if (!props.icon && !instanceProps.icon) {
        throw new Error("The 'icon' prop is required for MantineTabler.");
      }

      // @ts-expect-error TS2769
      return <MantineTabler size={16} {...props} {...instanceProps} ref={ref} />;
    },
  );

  IntermediateComponent.displayName = displayName;
  return IntermediateComponent;
}
