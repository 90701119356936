import { useTrackerUser } from "components/AuthProvider";
import { useAuthenticatedUserFromObjectPool } from "lib/frontend/cm/services/user";

function isServer() {
  return typeof window === "undefined";
}

function createUseSafeUser() {
  if (isServer()) return useTrackerUser;
  if (window.location.href.match(/\/benchmarking/)) return useTrackerUser;
  return useAuthenticatedUserFromObjectPool;
}

export const useSafeCrossAppUser = createUseSafeUser();
