import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";

import { UserWithCompany } from "lib/frontend/cm/utils/types";
import { User } from "prisma/cm/client";

// TODO: In the future this will be accurate, but for now we alias.

type GetUserResponse = { user: UserWithCompany; impersonatorUser: User };

type TAuthContextValue = {
  pending: boolean;
  impersonatorUser: User | null;
  user: UserWithCompany | null;
  refresh: () => void;
};

export const AuthContext = createContext<TAuthContextValue>({} as TAuthContextValue);

export const RQ_USER_QUERY_KEY = "user";

function useGetAuthUserQuery(options: UseQueryOptions) {
  return useQuery({
    queryKey: [RQ_USER_QUERY_KEY],
    queryFn: async () => {
      const res = await axios.get<GetUserResponse>("/api/v1/auth/user");
      return res.data as unknown as GetUserResponse;
    },
    ...options,
  });
}

function isServer() {
  return typeof window === "undefined";
}
function isClient() {
  return !isServer();
}

export function TrackerAuthProvider({ children }: { children: React.ReactNode }) {
  const [mounted, setMounted] = useState(false);

  // We don't want this to run until the UI gets to the client
  // so we delay the query execution until the component mounts.
  const getUserAuthQuery = useGetAuthUserQuery({ enabled: mounted });
  const authData = getUserAuthQuery.data as GetUserResponse | undefined;

  useEffect(() => {
    if (isClient()) setMounted(true);
  }, [setMounted]);

  useEffect(() => {
    const user = authData?.user as UserWithCompany;

    if (!user) return;

    (window as any).heap?.identify(getPrimaryEmail(user) ?? user?.id);
    (window as any).heap?.addUserProperties({
      name: user?.name,
      email: getPrimaryEmail(user),
      userId: user?.id,
    });
  }, [authData]);

  const value = useMemo(
    () => ({
      pending: !getUserAuthQuery.isFetched,
      user: authData?.user ?? null,
      impersonatorUser: authData?.impersonatorUser ?? null,
      refresh: getUserAuthQuery.refetch,
    }),
    [getUserAuthQuery.status],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useTrackerAuth() {
  return useContext(AuthContext);
}

export function useTrackerUser() {
  return useTrackerAuth().user;
}

function getPrimaryEmail(user: UserWithCompany): string | undefined {
  return user?.emails?.find((email) => email.isPrimary)?.email;
}
