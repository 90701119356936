import { EnvUtils } from "@/utils/EnvUtils";

import { useSafeCrossAppUser } from "./useSafeCrossAppUser";

export function useReturnToFifteenFiveUrl() {
  const user = useSafeCrossAppUser();

  if (!user?.company?.isFifteenFivePartner) {
    return null;
  }

  if (EnvUtils.isProd) {
    return "https://my.15five.com";
  } else {
    return "https://my.staging.15five.com";
  }
}
