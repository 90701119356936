import { SegmentedControlItem } from "@mantine/core";
import { lowerCase, reduce, startCase } from "lodash";

import {
  CompensationApprovalStatus,
  ReviewStatus,
  SalaryBenchmarkType,
  SalaryIncomeFrequency,
  SalaryIncreaseType,
  UserStatus,
  VariableInputMode,
} from "prisma/cm/client";
import { PosQueryIncludeFragment } from "store/objectPool/prismaObjectSync/queries";

import {
  AlwaysHideCol,
  AwardLetterModeEmployee,
  AwardLetterModePreview,
  BenchmarkTarget,
  BenchmarkTarget10,
  BenchmarkTarget25,
  BenchmarkTarget50,
  BenchmarkTarget75,
  BenchmarkTarget90,
  BenchmarkThreshold,
  BenchmarkThresholdMax,
  BenchmarkThresholdMid,
  BenchmarkThresholdMin,
  ColumnConfigType,
  CompensationApprovalStatusMap,
  ForbidCol,
  HideCol,
  HttpDelete,
  HttpGet,
  HttpPatch,
  HttpPost,
  HttpPut,
  LevelPercentileMap,
  LocalCurrency,
  ReviewStatusAll,
  ReviewStatusMap,
  SalaryBenchmarkTypeMap,
  SalaryIncomeFrequencyMap,
  SalaryIncreaseTypeMap,
  ShowCol,
  SuggestedNoteTypeBonus,
  SuggestedNoteTypeEquity,
  SuggestedNoteTypeRaise,
  USDollar,
  UserStatusMap,
  VariableInputModeMap,
} from "./types";

export const HTTP_GET: HttpGet = "GET";
export const HTTP_POST: HttpPost = "POST";
export const HTTP_PUT: HttpPut = "PUT";
export const HTTP_PATCH: HttpPatch = "PATCH";
export const HTTP_DELETE: HttpDelete = "DELETE";

export const BENCHMARK_TARGET_10: BenchmarkTarget10 = "10";
export const BENCHMARK_TARGET_25: BenchmarkTarget25 = "25";
export const BENCHMARK_TARGET_50: BenchmarkTarget50 = "50";
export const BENCHMARK_TARGET_75: BenchmarkTarget75 = "75";
export const BENCHMARK_TARGET_90: BenchmarkTarget90 = "90";

export const BENCHMARK_THRESHOLD_MIN: BenchmarkThresholdMin = "MIN";
export const BENCHMARK_THRESHOLD_MID: BenchmarkThresholdMid = "MID";
export const BENCHMARK_THRESHOLD_MAX: BenchmarkThresholdMax = "MAX";

export const BENCHMARK_THRESHOLD_TO_TARGET_MAP: Record<BenchmarkThreshold, BenchmarkTarget> = {
  [BENCHMARK_THRESHOLD_MIN]: BENCHMARK_TARGET_25,
  [BENCHMARK_THRESHOLD_MID]: BENCHMARK_TARGET_50,
  [BENCHMARK_THRESHOLD_MAX]: BENCHMARK_TARGET_75,
};

export const REVIEW_STATUS_ALL: ReviewStatusAll = "ALL";
export const REVIEW_STATUS_MAP: ReviewStatusMap = {
  [REVIEW_STATUS_ALL]: "All",
  ...reduce(
    Object.values(ReviewStatus),
    (result, status) => {
      result[status] = startCase(lowerCase(status as string));

      return result;
    },
    {} as Omit<ReviewStatusMap, ReviewStatusAll>,
  ),
};

export const SALARY_BENCHMARK_TYPE_MAP: SalaryBenchmarkTypeMap = {
  [SalaryBenchmarkType.BASE_SALARY]: "Base Salary",
  [SalaryBenchmarkType.TOTAL_PAY]: "OTE",
};

export const SALARY_INCREASE_TYPE_MAP: SalaryIncreaseTypeMap = {
  // consider de-duping with SalaryBenchmarkType down the road
  [SalaryIncreaseType.BASE_SALARY]: "Base Salary",
  [SalaryIncreaseType.TOTAL_PAY]: "OTE",
};

export const LEVEL_PERCENTILE_LABEL_MAP: LevelPercentileMap = {
  [BENCHMARK_TARGET_10]: "10%",
  [BENCHMARK_TARGET_25]: "25%",
  [BENCHMARK_TARGET_50]: "50%",
  [BENCHMARK_TARGET_75]: "75%",
  [BENCHMARK_TARGET_90]: "90%",
};

export const LEVEL_PERCENTILE_VALUE_MAP: LevelPercentileMap = {
  [BENCHMARK_TARGET_10]: "10",
  [BENCHMARK_TARGET_25]: "25",
  [BENCHMARK_TARGET_50]: "50",
  [BENCHMARK_TARGET_75]: "75",
  [BENCHMARK_TARGET_90]: "90",
};

export const COMPENSATION_APPROVAL_STATUS_MAP: CompensationApprovalStatusMap = {
  [CompensationApprovalStatus.PENDING]: "Pending",
  [CompensationApprovalStatus.IN_REVIEW]: "Submitted",
  [CompensationApprovalStatus.APPROVED]: "Approved",
  [CompensationApprovalStatus.REJECTED]: "Rejected",
};

export const USER_STATUS_MAP: UserStatusMap = {
  [UserStatus.PENDING]: "Pending",
  [UserStatus.INVITED]: "Invited",
  [UserStatus.ACTIVE]: "Active",
  [UserStatus.DISABLED]: "Disabled",
};

export const VARIABLE_INPUT_MODE_MAP: VariableInputModeMap = {
  [VariableInputMode.DOLLAR]: "Dollar",
  [VariableInputMode.PERCENT]: "Percent",
};

export const SALARY_INCOME_FREQUENCY_MAP: SalaryIncomeFrequencyMap = {
  [SalaryIncomeFrequency.HOURLY]: "Hourly",
  [SalaryIncomeFrequency.DAILY]: "Daily",
  [SalaryIncomeFrequency.WEEKLY]: "Weekly",
  [SalaryIncomeFrequency.BI_WEEKLY]: "Bi-weekly",
  [SalaryIncomeFrequency.SEMI_MONTHLY]: "Semi-monthly",
  [SalaryIncomeFrequency.MONTHLY]: "Monthly",
  [SalaryIncomeFrequency.YEARLY]: "Yearly",
  [SalaryIncomeFrequency.FIXED]: "Fixed",
};
export const SALARY_INCOME_FREQUENCY_DECIMAL_WHITELIST: SalaryIncomeFrequency[] = [
  SalaryIncomeFrequency.HOURLY,
  SalaryIncomeFrequency.DAILY,
];

export const REVIEW_TAB_PROMOTIONS: SegmentedControlItem = {
  value: "promotions",
  label: "Promotions",
};
export const REVIEW_TAB_BASE_SALARY: SegmentedControlItem = {
  value: "salary-old",
  label: "Going Away",
};
export const REVIEW_TAB_SALARY: SegmentedControlItem = {
  value: "salary",
  label: "Salary",
};

export const REVIEW_TAB_BONUS_TARGET: SegmentedControlItem = {
  value: "bonus-target",
  label: "Bonus Targets",
};
export const REVIEW_TAB_BONUS: SegmentedControlItem = { value: "bonus", label: "Bonus Awards" };
export const REVIEW_TAB_EQUITY: SegmentedControlItem = { value: "equity", label: "Equity Grants" };
export const REVIEW_TAB_SUMMARY: SegmentedControlItem = { value: "summary", label: "Summary" };
export const REVIEW_TAB_OUTLIERS: SegmentedControlItem = { value: "outliers", label: "Outliers" };
export const REVIEW_TABS: SegmentedControlItem[] = [
  REVIEW_TAB_PROMOTIONS,
  REVIEW_TAB_SALARY,
  REVIEW_TAB_BONUS,
  REVIEW_TAB_EQUITY,
  REVIEW_TAB_SUMMARY,
];

export const LOGIC_TABS: SegmentedControlItem[] = [
  REVIEW_TAB_SALARY,
  REVIEW_TAB_BONUS_TARGET,
  REVIEW_TAB_BONUS,
  REVIEW_TAB_EQUITY,
  REVIEW_TAB_OUTLIERS,
];

export const REVIEW_ANALYTICS_TAB_TOTALS: SegmentedControlItem = {
  value: "summary",
  label: "Totals",
};
export const REVIEW_ANALYTICS_TAB_PAY_EQUITY: SegmentedControlItem = {
  value: "pay-equity",
  label: "Pay Equity",
};

export const REVIEW_ANALYTICS_TABS: SegmentedControlItem[] = [
  REVIEW_ANALYTICS_TAB_PAY_EQUITY,
  REVIEW_ANALYTICS_TAB_TOTALS,
];

export const APPROVAL_TAB_BY_PROPOSER: SegmentedControlItem = {
  value: "proposer-budgets",
  label: "By Proposer",
};
export const APPROVAL_OR_ADMIN_TAB_BY_EMPLOYEE: SegmentedControlItem = {
  value: "employees",
  label: "All Employees",
};
export const APPROVAL_TABS: SegmentedControlItem[] = [
  APPROVAL_TAB_BY_PROPOSER,
  APPROVAL_OR_ADMIN_TAB_BY_EMPLOYEE,
];

export const ADMIN_TAB_BY_APPROVER: SegmentedControlItem = {
  value: "approvals",
  label: "Approvals",
};
export const ADMIN_TABS: SegmentedControlItem[] = [
  ADMIN_TAB_BY_APPROVER,
  APPROVAL_OR_ADMIN_TAB_BY_EMPLOYEE,
];

export const HEADER_TOOLTIP_BONUS_TARGET_ADMIN =
  "Bonus Target = Bonus Target Percentage * Current Annual Salary * Proration Factor (if any).";
export const HEADER_TOOLTIP_EMPLOYEES_ADMIN =
  "The number of employees the proposer will be responsible for in this review.";

export const SUGGESTED_NOTE_TYPE_RAISE: SuggestedNoteTypeRaise = "suggestedRaiseNote";
export const SUGGESTED_NOTE_TYPE_BONUS: SuggestedNoteTypeBonus = "suggestedBonusNote";
export const SUGGESTED_NOTE_TYPE_EQUITY: SuggestedNoteTypeEquity = "suggestedEquityNote";

export const CONFIG_COLUMN_TYPE_PROMOTIONS: ColumnConfigType = "promotions";
export const CONFIG_COLUMN_TYPE_SALARY: ColumnConfigType = "salary";
export const CONFIG_COLUMN_TYPE_BONUS: ColumnConfigType = "bonus";
export const CONFIG_COLUMN_TYPE_EQUITY: ColumnConfigType = "equity";
export const CONFIG_COLUMN_TYPE_SUMMARY: ColumnConfigType = "summary";
export const CONFIG_COLUMN_TYPE_APPROVALS: ColumnConfigType = "approvals";
export const CONFIG_COLUMN_TYPE_AWARD_LETTERS: ColumnConfigType = "awardLetters";

export const AWARD_LETTER_MODE_PREVIEW: AwardLetterModePreview = "PREVIEW";
export const AWARD_LETTER_MODE_EMPLOYEE: AwardLetterModeEmployee = "EMPLOYEE";

export const NOT_SPECIFIED = "Not Specified";

export const INVALID_CREDENTIALS = "invalid-credentials";

export const US_DOLLAR: USDollar = "USD";
export const LOCAL_CURRENCY: LocalCurrency = "LOCAL_CURRENCY";

export const SHOW: ShowCol = "show";
export const HIDE: HideCol = "hide";
export const ALWAYS_HIDE: AlwaysHideCol = "alwaysHide";
export const FORBID: ForbidCol = "forbid";

export const EmptyResultKeywords = {
  none: "None",
};

const RANGE_INCLUDES_FOR_FIELDS_LITERAL = {
  job: {
    include: {
      level: {
        include: {
          track: true,
        },
      },
      family: {
        include: {
          familyGroup: true,
        },
      },
    },
  },
  zone: true,
} as const;

export const USER_INCLUDES_FOR_FIELDS_LITERAL = {
  manager: true,
  performanceRatings: true,
  performanceAnswers: true,
  grants: true,
  emails: true,
  range: { include: RANGE_INCLUDES_FOR_FIELDS_LITERAL },
  objectives: {
    include: {
      keyResults: true,
    },
  },
} as const;

export const PROPOSAL_INCLUDES_FOR_FIELDS_LITERAL = {
  proposer: true,
  owner: {
    include: USER_INCLUDES_FOR_FIELDS_LITERAL,
  },
  range: { include: RANGE_INCLUDES_FOR_FIELDS_LITERAL },
} as const;

export const PROPOSAL_INCLUDES_FOR_FIELDS: PosQueryIncludeFragment<"Proposal"> = {
  ...PROPOSAL_INCLUDES_FOR_FIELDS_LITERAL,
};

export const NO_OPTION_VALUES = {
  noApprover: "no_approver",
  noParentApproval: "no_parent_approval",
  noProposer: "no_proposer",
};
